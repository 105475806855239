import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

const wrapRootComponent = ({ element }) => {
  const ConnectedRootElement = (
    <I18nextProvider i18n={i18n}>
      <element/>
    </I18nextProvider>
  );
  return ConnectedRootElement
}

export default wrapRootComponent;

/*export const onRouteUpdate = (location) => {
  if (location.hash) {
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView();
    }, 0);
  }
  try {
    window.dataLayer.push({'event': 'optimize.activate'});
  } catch (e) {}
};
*/