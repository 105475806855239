// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/atlassian/pipelines/agent/build/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-blog-index-en-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-blog-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/blog/index.fi.js" /* webpackChunkName: "component---src-pages-blog-index-fi-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-download-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-fi-myynti-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/fi/myynti.js" /* webpackChunkName: "component---src-pages-fi-myynti-js" */),
  "component---src-pages-fi-referenssitarinat-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/fi/referenssitarinat.js" /* webpackChunkName: "component---src-pages-fi-referenssitarinat-js" */),
  "component---src-pages-fi-tarjouksen-tekeminen-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/fi/tarjouksen-tekeminen.js" /* webpackChunkName: "component---src-pages-fi-tarjouksen-tekeminen-js" */),
  "component---src-pages-fi-yrittajyys-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/fi/yrittajyys.js" /* webpackChunkName: "component---src-pages-fi-yrittajyys-js" */),
  "component---src-pages-hinnasto-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/hinnasto/index.fi.js" /* webpackChunkName: "component---src-pages-hinnasto-index-fi-js" */),
  "component---src-pages-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.fi.js" /* webpackChunkName: "component---src-pages-index-fi-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-tarjousmalli-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/landing/tarjousmalli.js" /* webpackChunkName: "component---src-pages-landing-tarjousmalli-js" */),
  "component---src-pages-landing-tarjouspohja-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/landing/tarjouspohja.js" /* webpackChunkName: "component---src-pages-landing-tarjouspohja-js" */),
  "component---src-pages-landing-tarjouspyynto-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/landing/tarjouspyynto.js" /* webpackChunkName: "component---src-pages-landing-tarjouspyynto-js" */),
  "component---src-pages-mallikirjasto-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/mallikirjasto/index.fi.js" /* webpackChunkName: "component---src-pages-mallikirjasto-index-fi-js" */),
  "component---src-pages-pricelist-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/pricelist/index.js" /* webpackChunkName: "component---src-pages-pricelist-index-js" */),
  "component---src-pages-privacy-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-proposal-software-index-en-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/proposal-software/index.en.js" /* webpackChunkName: "component---src-pages-proposal-software-index-en-js" */),
  "component---src-pages-tarjoustyokalu-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/tarjoustyokalu/index.fi.js" /* webpackChunkName: "component---src-pages-tarjoustyokalu-index-fi-js" */),
  "component---src-pages-templatelibrary-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/templatelibrary/index.js" /* webpackChunkName: "component---src-pages-templatelibrary-index-js" */),
  "component---src-pages-terms-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-yritys-index-fi-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/yritys/index.fi.js" /* webpackChunkName: "component---src-pages-yritys-index-fi-js" */)
}

