import i18n from 'i18next';
import Backend from 'i18next-locize-backend';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const ALLOWED_LANGUAGES = ['en', 'fi'];

const lngDetector = new LanguageDetector();
lngDetector.addDetector({
  name: 'route',
  lookup(options) {
    try {
      const lang = window.location.pathname.split('/')[1];
      if(ALLOWED_LANGUAGES.indexOf(lang) > -1){
        return lang;
      }
      return 'en';
    } catch (e) {
      return 'en';
    }
  },

  cacheUserLanguage(lng, options) {
    
  }
});

i18n.use(Backend)
  .use(initReactI18next)
  .use(lngDetector)
  .init({
    react: {
      useSuspense: false
    },
    detection: {
      order: ['querystring', 'route', 'htmlTag'],
    },
    fallbackLng: 'en',
    ns: ['website'],
    defaultNS: 'website',
    //debug: true,
    backend: {
      projectId: 'd827a3de-b47b-4664-957c-4285a53b2c49',
      referenceLng: 'en'
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    }
  });


export default i18n;
